<template>
  <div
    class="aside-right d-flex aside-fixed right-aside-layout"
    id="kt_aside_right"
    ref="kt_aside_right"
    v-if="showToolbar"
  >
    <!--begin::Primary-->
    <div class="right-aside-padding">
      <b-row>
        <b-col>
          <button class="right-aside-close" @click="closeWindow()">Close</button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <center>
            <v-icon x-large style="font-size: 68px">mdi-file-excel</v-icon>
          </center>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <center>
            <h1><br />{{ getRightSide.record.title }}</h1>
          </center>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <center>
            <p>
              <br />{{ getRightSide.record.file_size }} -
              {{ formatDate(getRightSide.record.created_at) }}
            </p>
          </center>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_RIGHT_ASIDE } from "@/core/services/store/right_aside.module";

export default {
  name: "KTStickyToolbar",
  props: {
    showToolbar: Boolean,
  },
  computed: {
    ...mapGetters(["rightAsideConfig"]),
    getRightSide() {
      return this.rightAsideConfig;
    },
  },
  methods: {
    closeWindow() {
      document.body.classList.remove("aside-right-secondary-enabled");

      this.$store.dispatch(SET_RIGHT_ASIDE, {
        diplay: false,
        record: [],
      });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("en-GB", { dateStyle: "full" }).format(date);
    },
  },
  mounted() {},
};
</script>

<style>
.right-aside-padding {
  padding: 20px;
  width: 400px;
  overflow: hidden;
}
.right-aside-layout {
  border-left: 1px solid #e9eef3;
}
.right-aside-close {
  width: 34px;
  height: 15px;
  margin: 42.5px 24px 22px 40px;
  opacity: 0.8;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: left;
  color: #3f6f86;
}
</style>
